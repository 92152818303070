export const smoothScrollTo = (target: HTMLElement, duration: number) => {
	const start = window.scrollY;
	const end = target.offsetTop;
	const distance = end - start;
	const startTime = performance.now();
	const scroll = (currentTime: number) => {
		const elapsed = currentTime - startTime;
		const progress = Math.min(elapsed / duration, 1);
		const easeInOutQuad = progress < 0.5 ? 2 * progress * progress : 1 - Math.pow(-2 * progress + 2, 2) / 2;
		window.scrollTo(0, start + distance * easeInOutQuad);

		if (progress < 1) {
			requestAnimationFrame(scroll);
		}
	};

	requestAnimationFrame(scroll);
};
