import { baseApi } from 'shared/api/baseApi';

export const postcardsApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getPostcardInfo: build.query<{ generating: boolean; artefacts: any[] }, string>({
			query: (id) => ({
				url: `postcards/info/${id}`
			})
		}),
		createPostcard: build.mutation<any, { prompt: string }>({
			query: ({ prompt }) => {
				return {
					url: `postcards`,
					method: 'POST',
					data: {
						prompt
					}
				};
			}
		})
	})
});

export const { useCreatePostcardMutation, useLazyGetPostcardInfoQuery, useGetPostcardInfoQuery } = postcardsApi;
