import { Baloon, Gift, Snowfall, Tree } from '../../assets/images';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PrimaryLogo } from 'shared/lib/getIcon';
import { GradientPenIcon, GptzStarIcon, SmallLogoIcon } from 'shared/ui/Icons';
import { useSearchParams } from 'react-router-dom';
import { useScroll, useTransform, motion, useMotionValueEvent } from 'framer-motion';
import { Button, Form, Input, message, Modal, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { StarLoader } from '../../shared/ui/StarLoader';
import { useCreatePostcardMutation, useGetPostcardInfoQuery } from '../../entities/postcards';
import { getBaseApiUrl } from '../../shared/env';
import { smoothScrollTo } from '../../shared/lib';

const DEFAULT_IMG_ID = '12';
const DEFAULT_SHORT_TEXT = 'Пусть все твои тревоги уносят в лес единороги!';
const DEFAULT_LONG_TEXT =
	'Платформа предлагает постоянно обновляющуюся библиотеку AI-навыков, которые могут использоваться для выполнения различных задач. Каждый пользователь может найти нужный навык или создать свой собственный через интуитивно понятный редактор.';

export const Landing = () => {
	const [params, setURLParams] = useSearchParams();
	const postcardId = params.get('id') ?? DEFAULT_IMG_ID;
	const baseUrl = getBaseApiUrl();
	const sectionRef = useRef<HTMLDivElement>(null);
	const welcomeRef = useRef<HTMLDivElement | null>(null);
	const [isOpenedCreateModal, setIsOpenedCreateModal] = useState<boolean>(false);
	const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
	const [blur, setBlur] = useState(false);
	const [form] = Form.useForm<{ prompt: string }>();
	const { scrollYProgress } = useScroll({ target: welcomeRef, offset: ['start start', 'end end'] });
	const headerTop = useTransform(scrollYProgress, [0, 0.99, 1], [0, 0, -80]);
	const background = useTransform(
		scrollYProgress,
		[0, 0.5, 1],
		['linear-gradient(90deg, #008584 0%, #59F88A 100%)', 'linear-gradient(90deg, #008584 0%, #ffffff 50%)', '#ffffff']
	);
	const scale = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1.5, 2]);
	const giftLeft = useTransform(scrollYProgress, [0, 1], ['-412px', '-824px']);
	const baloonRight = useTransform(scrollYProgress, [0, 0.5, 1], ['-412px', '-618px', '-824px']);
	const marginBottom = useTransform(scrollYProgress, [0, 0.5, 1], [34, 0, 0]);
	const fontSize = useTransform(scrollYProgress, [0, 0.5, 1], ['186px', '128px', '128px']);
	const { generating, short, long, refetch, isError, isLoading, isSuccess } = useGetPostcardInfoQuery(postcardId, {
		refetchOnMountOrArgChange: true,
		selectFromResult: ({ data, isError, isLoading, isSuccess }) => {
			const short = data?.artefacts.find((artefact) => artefact.slug === 'generate_greetings_short')?.data;
			const long = data?.artefacts.find((artefact) => artefact.slug === 'generate_greetings_long')?.data;
			return {
				generating: data?.generating,
				short:
					data?.artefacts.find((artefact) => artefact.slug === 'generate_greetings_short')?.data ?? DEFAULT_SHORT_TEXT,
				long:
					data?.artefacts.find((artefact) => artefact.slug === 'generate_greetings_long')?.data ?? DEFAULT_LONG_TEXT,
				isError,
				isLoading,
				isSuccess
			};
		}
	});

	const [createPostcard, { isLoading: isCreateLoading }] = useCreatePostcardMutation();
	useMotionValueEvent(scale, 'change', (current) => {
		if (current === 2) {
			setBlur(true);
		}
		if (current < 1.8) {
			setBlur(false);
		}
	});

	const handleFormFinish = async () => {
		try {
			const { prompt } = await form.validateFields();
			const createdPostcard = await createPostcard({ prompt }).unwrap();
			form.resetFields();
			setURLParams({ id: createdPostcard.doc.id });
		} catch (e) {
			message.error('Что-то пошло не так...');
		}
	};

	const onCancelHandler = async () => {
		setIsOpenedCreateModal(false);
	};

	const sharePostcardLink = useCallback(() => {
		const currentUrl = window.location.href;
		navigator.clipboard
			.writeText(currentUrl)
			.then(() => message.success('Ссылка скопирована в буфер обмена!'))
			.catch(() => message.error('Не удалось скопировать ссылку.'));
	}, []);

	useEffect(() => {
		if (postcardId && !!generating) {
			const intervalId = setInterval(() => {
				refetch();
			}, 5000);

			return () => clearInterval(intervalId);
		}
		return;
	}, [postcardId, generating]);

	useEffect(() => {
		if (generating) {
			setConfirmLoading(generating);
		}
		if (!generating && !!confirmLoading) {
			setIsOpenedCreateModal(false);
			setConfirmLoading(false);
		}
	}, [generating]);

	useEffect(() => {
		const id = params.get('id');
		if (id && sectionRef.current && isSuccess) {
			smoothScrollTo(sectionRef.current, 2000);
		}
	}, [isSuccess]);

	return (
		<>
			<motion.div
				style={{ top: headerTop }}
				className='sticky top-0 z-20 bg-black text-white w-full flex items-center justify-center min-h-[--lp-header-height] opacity-80'
			>
				<a
					href='https://gptzator.ru/'
					className='flex items-center gap-x-2 w-full max-w-6xl'
					target='_blank'
					rel='noreferrer'
				>
					<div className='min-w-[90px]'>
						<PrimaryLogo className='w-full h-full' />
					</div>
					<span className='text-xl text-white font-bold'>GPTZATOR</span>
				</a>
			</motion.div>
			<main>
				<div className='relative h-[200vh]'>
					<div ref={welcomeRef} className='absolute -top-[--lp-header-height] left-0 w-full h-[200vh]'>
						<div className='sticky top-0 w-full h-screen bg-cover bg-no-repeat bg-landing flex items-center justify-center overflow-hidden'>
							<img src={Snowfall} alt='' className='absolute w-full h-full rotate-180 z-50' />
							<div className='relative flex flex-col items-center'>
								<GptzStarIcon className='text-2xl absolute -bottom-[80px] -left-[120px]' />
								<GptzStarIcon className='text-xs absolute -bottom-[60px] -left-[80px]' />
								<GptzStarIcon className='text-xs absolute -bottom-[220px] left-[200px]' />
								<motion.h1
									style={{ background, marginBottom, fontSize }}
									className='font-extrabold !bg-clip-text !text-transparent'
								>
									GPTZATOR
								</motion.h1>
								<p className='text-[32px] max-w-[482px] text-center text-white'>
									Генерируй открыточки , радуй близких и родных
								</p>
								<motion.img
									src={Gift}
									alt=''
									className={`absolute -top-[165px] object-cover transition-transform duration-100 ease-linear ${
										blur ? 'blur' : ''
									}`}
									style={{ scale, left: giftLeft }}
								/>
								<motion.img
									src={Baloon}
									alt=''
									className={`absolute -right-[412px] -top-[33px] object-cover transition-transform duration-100 ease-linear ${
										blur ? 'blur' : ''
									}`}
									style={{ scale, right: baloonRight }}
								/>
								<GptzStarIcon className='text-2xl absolute -top-[80px] -right-[120px]' />
								<GptzStarIcon className='text-xs absolute top-0 right-[280px]' />
								<GptzStarIcon className='text-xs absolute -top-[130px] right-[80px]' />
							</div>
						</div>
					</div>
				</div>
				<div
					ref={sectionRef}
					className='relative -mt-[--lp-header-height] min-h-screen flex items-center justify-center overflow-hidden bg-white'
				>
					<div className='w-full relative flex items-center justify-center'>
						<motion.div
							className='w-full p-6 flex items-center justify-center'
							initial={{ opacity: 0 }}
							whileInView={{ opacity: 1 }}
							viewport={{ margin: '100% 0px -10% 0px' }}
							transition={{ duration: 0.3, delay: 0.5, ease: 'easeInOut' }}
						>
							<div className='max-w-full w-[1200px] min-h-[400px] flex flex-col lg:grid grid-cols-2 relative z-20'>
								<div className='w-[600px] p-5 shrink-0 bg-white shadow-shdw1 flex flex-col'>
									{!generating && (
										<img
											className='w-full object-cover'
											src={`${baseUrl}postcards/img/${isError ? DEFAULT_IMG_ID : postcardId}`}
											alt=''
										/>
									)}
									{!!short && <div className='w-full p-10 text-dark-font text-center self-end mt-auto'>{short}</div>}
								</div>
								<div className='lg:pl-24 flex flex-col justify-center'>
									<h1 className='text-[38px] font-extrabold bg-grad3 bg-clip-text text-transparent mb-7'>
										Вот ваша открытонька
									</h1>
									<span className='text-dark-font'>{long}</span>
									<div className='flex gap-x-2 mt-16'>
										<Button size='large' block className='min-h-[60px] text-2xl' onClick={sharePostcardLink}>
											Поделиться
										</Button>
										<Button
											size='large'
											block
											type='primary'
											className='min-h-[60px] text-2xl'
											onClick={() => setIsOpenedCreateModal(true)}
										>
											Создать ещё
										</Button>
									</div>
								</div>
							</div>
						</motion.div>
						<motion.img
							initial={{ right: '-520px' }}
							whileInView={{ right: '-350px' }}
							viewport={{ margin: '100% 0px -10% 0px' }}
							transition={{ duration: 0.5, delay: 2, ease: 'easeInOut' }}
							src={Tree}
							alt=''
							className='absolute -translate-y-[50%] top-[50%] object-cover transition-transform duration-500 ease-in-out'
						/>
					</div>
					<Modal
						open={isOpenedCreateModal}
						width={1200}
						height={750}
						centered
						confirmLoading={confirmLoading}
						onCancel={onCancelHandler}
						closeIcon={<CloseOutlined className='text-xl text-white' />}
						footer={null}
						classNames={{
							content: '!min-h-[750px] !bg-dark-2 flex items-center justify-center',
							mask: '!bg-landing'
						}}
					>
						<div
							className={`flex flex-col items-center text-center gap-y-16 max-w-[212px] ${!generating ? 'hidden' : ''}`}
						>
							<StarLoader />
							<span className='text-white'>Уже генерируем... это займет до 30 секунд</span>
						</div>

						<Form
							form={form}
							onFinish={handleFormFinish}
							autoComplete='off'
							size='large'
							className={`max-w-full min-w-[700px] min-h-full ${generating ? 'hidden' : ''}`}
						>
							<div className='mb-8 flex gap-x-6'>
								<Typography.Title level={2} className='!text-white !font-bold !mb-0'>
									Кого хотите поздравить?
								</Typography.Title>
								<GradientPenIcon className='text-3xl' />
							</div>
							<Form.Item name='prompt'>
								<Input.TextArea
									autoSize={{ minRows: 4, maxRows: 10 }}
									disabled={generating || isCreateLoading || isLoading}
								/>
							</Form.Item>
							<Form.Item>
								<Button
									htmlType='submit'
									type='primary'
									size='large'
									loading={generating || isCreateLoading || isLoading}
									icon={<SmallLogoIcon className='text-dark-2 -mb-0.5 text-lg' />}
								>
									Генерировать
								</Button>
							</Form.Item>
						</Form>
					</Modal>
				</div>
			</main>
		</>
	);
};
