import axios from 'axios';

import { getBaseApiUrl } from '../env';

const baseURL = getBaseApiUrl();

const axiosInstance = axios.create({
	baseURL: baseURL,
	withCredentials: false
});

axiosInstance.interceptors.request.use(
	(config) => {
		config.params = {
			...config.params
		};

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
export { axiosInstance };
