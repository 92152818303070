import React from 'react';
import { Error } from '../../../assets/images';
import { Button } from 'antd';

export const ErrorFallback = () => {
	const onReloadHandler = () => {
		if (typeof window !== 'undefined') {
			window.location.reload();
		}
	};
	return (
		<div className='max-h-full h-screen overflow-y-auto w-full overflow-y-auto flex flex-wrap items-center justify-center gap-x-24'>
			<div>
				<h1 className='text-7xl font-bold uppercase'>Ошибка</h1>
				<p className='text-2xl opacity-60 my-6'>Что-то пошло не так...</p>
				<Button type='primary' onClick={onReloadHandler} size='large'>
					Перезагрузить страницу
				</Button>
			</div>
			<div>
				<img className='w-full rounded object-none' src={Error} alt='' />
			</div>
		</div>
	);
};
