import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';

import './index.css';
import { theme } from '../shared/config/theme/themeConfig';

import { antdRuLocale, validateRuMessages } from '../shared/config';
import { getSentryDsn, getSentryTunnel, getEnvironment, isDevelopMode } from '../shared/env';
import { Landing } from './layouts/Landing';
import { ErrorFallback } from '../widgets/ErrorFallback';
import { useLocation } from 'react-router-dom';

Sentry.init({
	dsn: getSentryDsn(),
	integrations: [new Sentry.BrowserTracing()],
	tracesSampleRate: 1.0,
	tunnel: getSentryTunnel(),
	environment: getEnvironment(),
	enabled: !isDevelopMode
});

function App() {
	const location = useLocation();
	return (
		<Suspense>
			<ConfigProvider
				locale={antdRuLocale}
				form={{
					validateMessages: validateRuMessages
				}}
				theme={theme}
			>
				<Sentry.ErrorBoundary key={location.pathname} fallback={<ErrorFallback />}>
					<Landing />
				</Sentry.ErrorBoundary>
			</ConfigProvider>
		</Suspense>
	);
}

export default App;
