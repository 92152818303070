export const getBaseApiUrl = (): string => {
	const { REACT_APP_API_URL } = window.__env__ || process.env;
	return REACT_APP_API_URL || window.location.origin;
};

export const getSentryDsn = (): string => {
	const { REACT_APP_SENTRY_DSN } = window.__env__ || process.env;
	return REACT_APP_SENTRY_DSN || '';
};

export const getSentryTunnel = (): string => {
	const { REACT_APP_SENTRY_TUNNEL } = window.__env__ || process.env;
	return REACT_APP_SENTRY_TUNNEL || '';
};

export const isDevelopMode = process.env.REACT_APP_NODE_ENV === 'development';

export const isDevelopUrl = window.location.origin.includes('.ladcloud');

export const getEnvironment = () => {
	if (!isDevelopMode && !isDevelopUrl) {
		return 'production';
	}
	return 'development';
};
