import React from 'react';
import { motion } from 'framer-motion';

export const StarLoader = () => {
	return (
		<div className='min-w-[212px] w-[120px] h-[120px] min-h-[160px]'>
			<svg xmlns='http://www.w3.org/2000/svg' width='222' height='171' viewBox='0 0 222 171' fill='none'>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0.1, 0.3, 0.6],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M195.878 34.3989L196.378 35.555C197.923 39.1308 200.942 41.8606 204.656 43.0376L206.732 43.6956L204.656 44.3536C200.942 45.5305 197.923 48.2604 196.378 51.8362L195.878 52.9922L195.379 51.8362C193.834 48.2604 190.814 45.5305 187.101 44.3536L185.025 43.6956L187.101 43.0376C190.814 41.8606 193.834 39.1308 195.379 35.555L195.878 34.3989Z'
					fill='#89F595'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0, 0.2, 0.5, 0.8, 1],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M81.3325 137.013L82.984 140.836C84.5287 144.412 87.5487 147.142 91.2618 148.319L96.4512 149.964L91.2618 151.609C87.5487 152.785 84.5287 155.515 82.984 159.091L81.3325 162.914L79.681 159.091C78.1363 155.515 75.1164 152.785 71.4033 151.609L66.2139 149.964L71.4033 148.319C75.1164 147.142 78.1363 144.412 79.681 140.836L81.3325 137.013Z'
					fill='#89F595'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0, 0.2, 0.5, 0.8, 1],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M214.268 99.1733L215.246 101.438C215.917 102.992 217.23 104.179 218.845 104.691L221.804 105.629L218.845 106.567C217.23 107.078 215.917 108.265 215.246 109.82L214.268 112.084L213.289 109.82C212.618 108.265 211.305 107.078 209.69 106.567L206.731 105.629L209.69 104.691C211.305 104.179 212.618 102.992 213.289 101.438L214.268 99.1733Z'
					fill='#05BDBD'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0.1, 0.3, 0.6],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M10.4254 113.649L12.0079 117.312C12.6795 118.867 13.9925 120.054 15.6069 120.566L20.1994 122.021L15.6069 123.477C13.9925 123.989 12.6795 125.176 12.0079 126.73L10.4254 130.394L8.84285 126.73C8.17125 125.176 6.85823 123.989 5.24382 123.477L0.651367 122.021L5.24382 120.566C6.85823 120.054 8.17125 118.867 8.84286 117.312L10.4254 113.649Z'
					fill='#05BDBD'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0, 0.2, 0.5, 0.8, 1],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M93.1951 28.3418L99.9102 43.8865C100.582 45.4412 101.895 46.6281 103.509 47.1398L121.972 52.992L103.509 58.8442C101.895 59.3559 100.582 60.5428 99.9102 62.0975L93.1951 77.6422L86.48 62.0975C85.8084 60.5428 84.4954 59.3559 82.881 58.8442L64.418 52.992L82.881 47.1398C84.4954 46.6281 85.8084 45.4412 86.48 43.8865L93.1951 28.3418Z'
					fill='#05BDBD'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0.1, 0.3, 0.6],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M145.648 77.6421C146.178 78.8685 147.213 79.8048 148.487 80.2084L148.736 80.2874L148.487 80.3663C147.213 80.77 146.178 81.7063 145.648 82.9327C145.118 81.7063 144.082 80.77 142.809 80.3663L142.56 80.2874L142.809 80.2084C144.082 79.8048 145.118 78.8685 145.648 77.6421Z'
					fill='#05BDBD'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0, 0.2, 0.5, 0.8, 1],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M159.867 126.916L160.726 128.904C160.877 129.254 161.172 129.521 161.534 129.635L163.927 130.394L161.534 131.152C161.172 131.267 160.877 131.534 160.726 131.883L159.867 133.872L159.008 131.883C158.857 131.534 158.562 131.267 158.199 131.152L155.807 130.394L158.199 129.635C158.562 129.521 158.857 129.254 159.008 128.904L159.867 126.916Z'
					fill='#89F595'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0, 0.2, 0.5, 0.8, 1],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M49.2153 0.950195L50.0743 2.93866C50.2252 3.28799 50.5202 3.55468 50.8829 3.66967L53.2753 4.42795L50.8829 5.18624C50.5202 5.30122 50.2252 5.56791 50.0743 5.91725L49.2153 7.90571L48.3563 5.91725C48.2054 5.56791 47.9103 5.30122 47.5476 5.18624L45.1553 4.42795L47.5476 3.66967C47.9103 3.55468 48.2054 3.28799 48.3563 2.93865L49.2153 0.950195Z'
					fill='#89F595'
				/>
				<motion.path
					animate={{
						opacity: [1, 0.2, 1]
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						times: [0, 0.2, 0.5, 0.8, 1],
						repeat: Infinity,
						repeatDelay: 1
					}}
					d='M193.161 166.914L193.5 167.699C193.651 168.049 193.946 168.315 194.309 168.43L195.296 168.743L194.309 169.056C193.946 169.171 193.651 169.438 193.5 169.787L193.161 170.573L192.822 169.787C192.671 169.438 192.376 169.171 192.013 169.056L191.025 168.743L192.013 168.43C192.376 168.315 192.671 168.049 192.822 167.699L193.161 166.914Z'
					fill='#89F595'
				/>
			</svg>
		</div>
	);
};
